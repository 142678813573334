var slice = [].slice;

import {
  Controller
} from '@hotwired/stimulus';

export default (function() {
  var _Class, _getLenderElement, _getProvidedAtElement;

  _Class = class extends Controller {
    connect() {
      ['cocooned:after-insert', 'cocooned:after-remove'].forEach((event) => {
        return this.element.addEventListener(event, this.toggleCloneLink.bind(this));
      });
      return this.element.addEventListener('cocooned:before-insert', this.conditionallyClone.bind(this));
    }

    conditionallyClone(event) {
      if (this.isCloneEvent(event)) {
        return this.cloneFields(event.detail.node);
      }
    }

    activeIndications() {
      return this.indicationTargets.filter(function(indication) {
        return indication.style.display !== 'none';
      });
    }

    isCloneEvent(event) {
      return this.cloneLinkTarget === event.detail.originalEvent.target;
    }

    toggleCloneLink() {
      var hasNoIndications;
      hasNoIndications = this.activeIndications().length === 0;
      return this.cloneLinkTarget.classList.toggle('d-none', hasNoIndications);
    }

    cloneFields(node) {
      var lastIndication, ref;
      ref = this.activeIndications(), [lastIndication] = slice.call(ref, -1);
      _getLenderElement(node).value = _getLenderElement(lastIndication).value;
      return _getProvidedAtElement(node).value = _getProvidedAtElement(lastIndication).value;
    }

  };

  _Class.targets = ['cloneLink', 'indication'];

  // Private
  _getLenderElement = function(indication) {
    return indication.querySelector('.trade_indications_lender select');
  };

  _getProvidedAtElement = function(indication) {
    return indication.querySelector('.trade_indications_provided_at input');
  };

  return _Class;

}).call(this);
