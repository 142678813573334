import {
  Controller
} from '@hotwired/stimulus';

import * as bootstrap from 'bootstrap';

import AliceUtilities from '@/custom/utilities';

export default (function() {
  var _Class, checkAllCheckboxes, resetFormToSelectOnlyElement;

  // Connects to data-controller="filter"
  _Class = class extends Controller {
    connect() {
      // Set focus on the first text/search input after the offcanvas is shown
      return this.element.addEventListener('shown.bs.offcanvas', (event) => {
        var selector;
        selector = 'input:not([type="hidden"]):not([type="radio"]):not([type="checkbox"])' + ':not([aria-hidden="true"])';
        return this.formTarget.querySelector(selector).focus();
      });
    }

    // Select all options for the provided filter section
    selectAll(event) {
      AliceUtilities.showLoadingIndicator();
      checkAllCheckboxes(event.target);
      return this.formTarget.requestSubmit();
    }

    // Uncheck all options for the provided filter section except the selected option
    selectOnly(event) {
      var container, thisCheckbox;
      AliceUtilities.showLoadingIndicator();
      container = event.target.closest('.select-only-container');
      thisCheckbox = container.querySelector('input[type=checkbox]');
      resetFormToSelectOnlyElement(thisCheckbox);
      return this.formTarget.requestSubmit();
    }

    submit(event) {
      var formData, newUrl, params;
      formData = new FormData(this.formTarget);
      params = new URLSearchParams(formData);
      newUrl = `${this.formTarget.action}?${params.toString()}`;
      // Just replace this Turbo Frame if it exists, otherwise fallback to a full page visit
      Turbo.visit(newUrl, {
        frame: 'filterable_list',
        action: 'advance'
      });
      return bootstrap.Offcanvas.getOrCreateInstance(this.element).hide();
    }

  };

  _Class.targets = ['form'];

  resetFormToSelectOnlyElement = function(targetElement) {
    var checkbox, filterSection, i, j, len, len1, radio, ref, ref1;
    filterSection = targetElement.closest('.js-filter-section');
    ref = filterSection.querySelectorAll('input[type=checkbox]');
    for (i = 0, len = ref.length; i < len; i++) {
      checkbox = ref[i];
      checkbox.checked = false;
    }
    ref1 = filterSection.querySelectorAll('input[type=radio]');
    for (j = 0, len1 = ref1.length; j < len1; j++) {
      radio = ref1[j];
      radio.checked = false;
    }
    return targetElement.checked = true;
  };

  checkAllCheckboxes = function(targetElement) {
    var checkbox, filterSection, i, len, ref, results;
    filterSection = targetElement.closest('.js-filter-section');
    ref = filterSection.querySelectorAll('input[type=checkbox]');
    results = [];
    for (i = 0, len = ref.length; i < len; i++) {
      checkbox = ref[i];
      results.push(checkbox.checked = true);
    }
    return results;
  };

  return _Class;

}).call(this);
